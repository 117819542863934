@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

:root {
  --white-smoke: #f2f2f2ff;
  --gunmetal: #29333D;
  --charcoal: #404e5cff;
  --paynes-gray: #485867ff;
  --paynes-gray-2: #4f6272ff;
  --uranian-blue: #a2d6f9ff;
  --carolina-blue: #86bbd8ff;
}

* {
  font-family: "Roboto", sans-serif !important;
}

body {
  overflow-y: scroll;
  scroll-behavior: smooth;
  transition: none !important;
  /* disable transitions to the top of page */
  background-color: var(--gunmetal) !important;
}

.btn-avss {
  background-color: var(--paynes-gray-2) !important;
  color: var(--white-smoke) !important;
  border: 2px outset var(--paynes-gray);
  border-radius: 6px;
  padding: 5px 0px;
}

.btn-avss:hover {
  background-color: var(--white-smoke) !important;
  color: var(--gunmetal) !important;
  transition: all 0.2s ease-in-out;
}

.box-shadow-1 {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px !important;
}